import { type ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPostFeedPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import type { GetPostFeedPageResponse } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  getPostPage,
  getPostPagePreview,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import type { GetPostPageResponse } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { normalizePostV3 } from '../external/common/services/post-utils';

const getLanguageCode = (platformApi: PlatformApi) => {
  return platformApi.window.multilingual.currentLanguage || (platformApi.site.language ?? 'en');
};

const resolvePostPageData = (response: GetPostFeedPageResponse | GetPostPageResponse) => {
  if ('postPage' in response) {
    return response.postPage;
  } else if ('postFeedPage' in response) {
    const postFeedPage = response.postFeedPage ?? {};
    const post = postFeedPage.posts?.posts?.[0] ?? {};
    const tagIds = post.tagIds ?? [];
    const categoryIds = post.categoryIds ?? [];
    const allTags = postFeedPage.tags ?? [];
    const allCategories = postFeedPage.categories ?? [];

    const filteredCategories = allCategories.filter((category) =>
      category.id ? categoryIds.includes(category.id) : false,
    );
    const filteredTags = allTags.filter((tag) => (tag.id ? tagIds.includes(tag.id) : false));

    return {
      ...postFeedPage,
      post,
      categories: filteredCategories,
      tags: filteredTags,
    };
  }
};

interface GetPostAndCategoriesParams {
  platformApi: PlatformApi;
  flowAPI: ViewerScriptFlowAPI;
  postId: string;
  instance: string;
}

export const getPostPageData = async ({
  platformApi,
  flowAPI,
  postId,
  instance,
}: GetPostAndCategoriesParams) => {
  let postResponsePromise;

  if (!postId && flowAPI.environment.isViewer) {
    throw new Error('No post id');
  }

  if (postId) {
    postResponsePromise = getViewerPostRequest({
      postId,
      instance,
      platformApi,
      flowAPI,
    });
  } else {
    postResponsePromise = getEditorPostRequest({
      flowAPI,
      languageCode: getLanguageCode(platformApi),
    });
  }

  try {
    const postResponse = await postResponsePromise;

    if (!postResponse.data) {
      throw new Error('No post');
    }

    const postPageData = resolvePostPageData(postResponse.data);

    if (!postPageData) {
      throw new Error('No post page data available');
    }

    const { post, tags, relatedPosts, categories, appData, translations } = postPageData;

    if (!post) {
      throw new Error('No post data available');
    }

    return {
      post: {
        ...normalizePostV3(post),
        tags,
      },
      relatedPosts,
      categories,
      appData,
      translations,
    };
  } catch (error: any) {
    if (error?.response?.status) {
      platformApi.seo.setSeoStatusCode(error.response.status);
    } else {
      platformApi.seo.setSeoStatusCode(500);
    }

    throw error;
  }
};

interface GetViewerPostRequestParams {
  platformApi: PlatformApi;
  flowAPI: ViewerScriptFlowAPI;
  postId: string;
  instance?: string;
}

const getViewerPostRequest = async ({
  platformApi,
  flowAPI,
  postId,
  instance,
}: GetViewerPostRequestParams) => {
  const params = {
    languageCode: getLanguageCode(platformApi),
    translationsName: 'main',
  };

  return instance
    ? flowAPI.httpClient.request(
        getPostPagePreview({
          draftPostId: postId,
          ...params,
        }),
        { signedInstance: instance },
      )
    : flowAPI.httpClient.request(
        getPostPage({
          postId,
          ...params,
        }),
      );
};

interface GetEditorPostRequestParams {
  flowAPI: ViewerScriptFlowAPI;
  languageCode: string;
}

const getEditorPostRequest = ({ flowAPI, languageCode }: GetEditorPostRequestParams) => {
  return flowAPI.httpClient.request(
    getPostFeedPage({
      languageCode,
      page: 1,
      pageSize: 1,
      includeContent: true,
      includeInitialPageData: true,
    }),
  );
};
