import { get } from 'lodash';
import { createRtfFormatter } from '@wix/yoshi-flow-editor';
import { getOuterUrl, getCustomPostUrl } from '@wix/communities-blog-client-common';
import { DISABLE_POST_COMMENTS_SUCCESS } from '../../comments/actions/disable-post-comments-types';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../../comments/actions/enable-post-comments-types';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../post-page/actions/fetch-recent-posts';
import { FETCH_RELATED_POSTS_SUCCESS } from '../../post-page/actions/fetch-related-posts';
import { FETCH_SEARCH_SUCCESS } from '../../search/actions/fetch-search-types';
import { FETCH_ARCHIVE_SUCCESS } from '../actions/fetch-archive';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../actions/fetch-category-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../actions/fetch-feed-posts';
import { FETCH_HASHTAG_POSTS_SUCCESS } from '../actions/fetch-hashtag-posts';
import { FETCH_POST_SUCCESS } from '../actions/fetch-post';
import { FETCH_TAG_POSTS_SUCCESS } from '../actions/fetch-tag-posts';
import { PIN_POST_SUCCESS } from '../actions/pin-post-types';
import { PUBLISH_POST_SUCCESS } from '../actions/publish-post-types';
import { SET_POSTS } from '../actions/set-posts';
import { UNPIN_POST_SUCCESS } from '../actions/unpin-post-types';
import { getLocalizationApi } from '../controller/localization-api';
import { isUnpublished } from '../selectors/post-selectors';
import { getEditPostUrl } from '../services/get-post-url';
import {
  INCREMENT_POST_LIKE_COUNT_FAILURE,
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
} from '../store/post-likes/post-likes-actions-types';
import { getPostPageSectionUrl, getTopology } from '../store/topology/topology-selectors';

const POST_ACTIONS = [
  SET_POSTS,
  FETCH_POST_SUCCESS,
  FETCH_CATEGORY_POSTS_SUCCESS,
  FETCH_TAG_POSTS_SUCCESS,
  FETCH_FEED_POSTS_SUCCESS,
  FETCH_RECENT_POSTS_SUCCESS,
  FETCH_RELATED_POSTS_SUCCESS,
  FETCH_SEARCH_SUCCESS,
  PUBLISH_POST_SUCCESS,
  FETCH_ARCHIVE_SUCCESS,
  FETCH_HASHTAG_POSTS_SUCCESS,
  PIN_POST_SUCCESS,
  UNPIN_POST_SUCCESS,
  ENABLE_POST_COMMENTS_SUCCESS,
  DISABLE_POST_COMMENTS_SUCCESS,
];

const POST_LIKE_ACTIONS = [
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
  INCREMENT_POST_LIKE_COUNT_FAILURE,
];

const POST_FIELDS = [
  'firstPublishedDateFormatted',
  'viewCountFormatted',
  'likeCountFormatted',
  'totalCommentsFormatted',
];

export const postMetadataMiddleware = ({ isSSR, language, localizePosts = false }) => {
  let localizationApi;

  return (store) => (next) => async (action) => {
    if (POST_ACTIONS.includes(action.type)) {
      const posts = getPosts(action);

      posts.forEach((post) => {
        if (!post.link) {
          insertPostLink(post, store);
        }
      });
    }

    if (localizePosts) {
      if (POST_ACTIONS.includes(action.type)) {
        const posts = getPosts(action);

        if (!arePostsLocalized(posts)) {
          localizationApi = await getLocalizationApi({ isSSR });
        }

        const rtfFormatter = createRtfFormatter(language);
        posts.forEach((post) => {
          localizationApi &&
            localizationApi.localizePost({
              post,
              language,
              useICU: true,
              rtfFormatter,
            });
        });
      } else if (POST_LIKE_ACTIONS.includes(action.type)) {
        localizationApi = await getLocalizationApi({ isSSR });
        localizationApi && localizationApi.localizeLikeCount(action.payload, language);
      }
    }

    return next(action);
  };
};

function getPosts({ type, payload } = {}) {
  switch (type) {
    case FETCH_SEARCH_SUCCESS:
      return get(payload, 'posts', []);
    case FETCH_POST_SUCCESS:
      const post = get(payload, 'post');
      return post ? [post] : [];
    default:
      return Array.isArray(payload) ? payload : [payload];
  }
}

function arePostsLocalized(posts) {
  return posts.every((post) => POST_FIELDS.every((field) => post.hasOwnProperty(field)));
}

function insertPostLink(post, store) {
  if (isUnpublished(post)) {
    const topology = getTopology(store.getState());
    post.link = getEditPostUrl(topology, post.id);
  } else if (post.slug) {
    const postPageSectionUrl = getPostPageSectionUrl(store.getState());
    post.link =
      getCustomPostUrl(store.getState(), post.slug) ||
      getOuterUrl(`/${post.slug}`, postPageSectionUrl);
  }
}
