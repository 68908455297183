import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { MEMBERS_ABOUT } from '@wix/app-definition-ids';
import { viewPost } from '@wix/ambassador-blog-v3-post/http';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  POST_STATUS,
  generatePostPageSEOTags,
  getAppConfig,
  getCustomPostUrl,
  toSeoTranslatedPage,
  resolveId,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_TOGGLE_POST_INDEX_SEO_SETTINGS } from '@wix/communities-blog-experiments';
import { MEMBERS_PROFILE_WIDGET_ID } from '../../../../common/members';
import { type AppData } from '../../../../viewer.app';
import { navigateToCustomPostUrl } from '../../../common/actions/navigate-within-blog';
import { pageOpened } from '../../../common/actions/page-opened';
import type { PlatformApi } from '../../../common/controller/platform-api';
import { extractMediaUrlsFromDraftOrRichContent } from '../../../common/helpers/extract-media-urls-from-draft-or-rich-content';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import { POST_PAGE } from '../../../common/services/detect-route';
import {
  getIsMobile,
  isEditor,
  isSSR,
  isSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getCategoriesMap } from '../../../common/store/categories/categories-selectors';
import { getIsDemoMode } from '../../../common/store/instance-values/instance-values-selectors';
import {
  type AppStore,
  type ControllerConfig,
  type FlowAPI,
  type NormalizedPost,
  type RedirectFn,
} from '../../../common/types';
import fetchPricingPlansUrl from '../../actions/fetch-princing-plans-url';
import { getReadingSessionId } from '../../selectors/reading-session-id-selector';
import { fetchRecentPostsAndComments } from '../fetch-data-for-post-widgets';

interface PostPageRouterPostHandlerParams {
  post: NormalizedPost;
  store: AppStore;
  redirect: RedirectFn;
  platformApi: PlatformApi;
  flowAPI: FlowAPI;
  appData: AppData;
  controllerConfig: ControllerConfig;
}

export const postPageRouterPostHandler = async ({
  post,
  store,
  redirect,
  platformApi,
  flowAPI,
  controllerConfig,
}: PostPageRouterPostHandlerParams) => {
  const state = store.getState();
  // HACK: multilingual language change in post page redirect to translation
  const lang = getQueryLocale(state) as string;

  if (lang && lang !== post.language) {
    const translation = post.translations?.find((trans) => trans.language === lang);

    if (translation) {
      store.dispatch(
        navigateToCustomPostUrl(getCustomPostUrl(state, translation.slug), `/${translation.slug}`),
      );
      return;
    } else {
      const error = new Error('Post language mismatch');
      // @ts-expect-error
      error.status = 404;
      throw error;
    }
  }

  if (post.status !== POST_STATUS.published) {
    redirect(`/${resolveId(post)}/edit`);
  }

  if (post.canSeePaidContent === false) {
    await store.dispatch(fetchPricingPlansUrl(post));
  }

  const isMobile = getIsMobile(state);
  // @ts-expect-error
  const referrer = platformApi.window.referrer;

  !isSSR(state) &&
    store.dispatch(
      pageOpened({
        page: POST_PAGE,
        post,
        isMobile,
        isEditor: isEditor(state),
        readingSessionId: getReadingSessionId(state),
        referrer,
      }),
    );

  if (!getIsDemoMode(state) && !isSSR(state) && !flowAPI.environment.isSEO) {
    flowAPI.httpClient.request(viewPost({ postId: resolveId(post) as string })).catch(() => {}); // Ignore if rate-limited
  }

  if (isSite(state)) {
    let mediaUrls;

    if (post.content) {
      try {
        mediaUrls = extractMediaUrlsFromDraftOrRichContent(post.content);
      } catch (e) {}
    }

    let ownerUrl;

    try {
      const api = await platformApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);

      if (api) {
        ownerUrl = await api.getSectionUrl({
          appDefinitionId: MEMBERS_ABOUT,
          sectionId: 'about',
          widgetId: MEMBERS_PROFILE_WIDGET_ID,
          memberId: post.owner?.slug,
        });
      }
    } catch (e) {}

    const translatedPages = (post?.translations ?? []).map((translation) =>
      toSeoTranslatedPage(translation, platformApi.window.multilingual.siteLanguages),
    );

    const itemData = generatePostPageSEOTags({
      appConfig: getAppConfig(state),
      post,
      isTogglePostIndexSEOExperimentEnabled: isExperimentEnabled(
        state,
        EXPERIMENT_TOGGLE_POST_INDEX_SEO_SETTINGS,
      ),
      categoriesMap: getCategoriesMap(state),
      mediaUrls,
      customPostUrl: getCustomPostUrl(state, post.slug),
      multilingual: platformApi.window.multilingual,
      ownerUrl,
      translatedPages,
    });

    const postPageSEOTags = {
      itemType: ITEM_TYPES.BLOG_POST,
      itemData,
      seoData: post.seoData,
    };

    platformApi.seo.renderSEOTags(postPageSEOTags);
  }

  let commentsControllerPromise;

  if (platformApi.seo.isInSEO()) {
    const { initWixCommentsController } = await import('../wix-comments');

    commentsControllerPromise = initWixCommentsController({
      controllerConfig,
      platformApi,
      store,
      flowAPI,
      loadSSR: true,
    });
  }

  await Promise.all([
    commentsControllerPromise,
    fetchRecentPostsAndComments({
      store,
      post,
    }),
  ]);
};
